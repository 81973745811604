import React from "react";
import { isEmpty } from "lodash";
// enums
import { EViewType } from "cms/enums/EViewType";
// utilities
import SanitizeHTMLString from "cms/utilities/SanitizeHTMLString";
// interfaces
import ILanguageVersionDetail from "cms/interfaces/ILanguageVersionDetail";
import ILanguageVersionList from "cms/interfaces/ILanguageVersionList";


type TRMDRTextContent = {

    parentLanguageVersion?: ILanguageVersionList|ILanguageVersionDetail|null;
    languageVersions?: ILanguageVersionList[]|ILanguageVersionDetail[]|null;

    isLoading?:boolean;

}

function RMDRTextContent ( props:TRMDRTextContent ) {

    if ( !isEmpty(props.parentLanguageVersion) && props.parentLanguageVersion?.viewType === EViewType.TEXT ) {

        const content = props.parentLanguageVersion.value?.replaceAll("classname=\"", "class=\"");
        
        return (
            <div className="ck-content" dangerouslySetInnerHTML={{ __html: SanitizeHTMLString(content)}} />
        );

    }

    return null;

}

const Component = React.memo(RMDRTextContent, ( prevProps:TRMDRTextContent, nextProps:TRMDRTextContent ) => {
    console.log("RMDRTextContent.memo", { prevProps, nextProps });
    return !( 
        prevProps.isLoading !== nextProps.isLoading 
        || prevProps.parentLanguageVersion !== nextProps.parentLanguageVersion
    )
});

export default Component;
export type {
    TRMDRTextContent
};