import React, { Suspense, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";
// components
import SEO from "components/SEO";
// actions
import CoreAction from "core/actions/common/CoreAction";
import ContentMetricsServiceAction from "cms/actions/screens/Content/ContentMetricsServiceAction";
// interfaces
import IClientSiteSettingDetail from "cms/interfaces/IClientSiteSettingDetail";
import ILanguageVersionDetail from "cms/interfaces/ILanguageVersionDetail";
import IClientLanguage from "core/interfaces/IClientLanguage";
// utilities
import LanguageVersionSelector from "cms/utilities/LanguageVersionSelector";
// enums
import { EContentMetricsType } from "core/enums/EContentMetricsType";
// styles
import 'react-tabs/style/react-tabs.css';

// lazy element
const SliderLazy = React.lazy(() => import("components/Slider"));
const MainPageItemLazy = React.lazy(() => import("components/MainPageItem"));


function mapStateToProps ( state: any ) {
    console.log("Home.mapStateToProps: ", state);

    const webApplicationLanguage:IClientLanguage    = state.CoreStore.language?.payload;
    const webApplicationLanguageCode:string         = webApplicationLanguage?.code;

    const siteSettingDetailPayload:IClientSiteSettingDetail    = state.SiteSettingStore.details?.payload;
    
    return {
        
        webApplicationLanguage,
        webApplicationLanguageCode,

        siteSettingDetailPayload

    }
}

function mapDispatchToProps ( dispatch: Dispatch ) {
    const coreAction = new CoreAction(dispatch);
    const contentMetricsServiceAction = new ContentMetricsServiceAction(dispatch);

    return {

        _viewEvent: ( contentId:string, webApplicationLanguageCode:string ) => {
            contentMetricsServiceAction.post({ 
                servicePayload: { 
                    body: {
                        contentId: contentId,
                        metricType: EContentMetricsType.VIEV
                    }, 
                    language: webApplicationLanguageCode 
                } 
            });
        },
        
        // others
        _hideLoader: () => {
            coreAction.hideLoader();
        },

        _showLoader: () => {
            coreAction.showLoader();
        },

    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);


/* types */
type PropsFromRedux = ConnectedProps<typeof connector>
type THome<T> = {

} & T;


/* component */
function Loader () {

    return (
        <></>
    )

}

function Home ( props:THome<PropsFromRedux> ) {
    console.log("Home.rendered: ", props);
    
    useEffect(
        () => {

            const contentId:string|null = LanguageVersionSelector<ILanguageVersionDetail>({
                languageVersions: props.siteSettingDetailPayload.languageVersions,
                filters: [{
                    name: "contentMetricsMainPageId"
                }],
                type: "find"
            })?.value;

            if ( !isEmpty(contentId) && contentId ) props._viewEvent( contentId, props.webApplicationLanguageCode );

        }, []
    )

    return (
        <>
            <SEO siteSettingPayload={props.siteSettingDetailPayload} type="home-page" />

            <Suspense fallback={<div></div>}>
                <SliderLazy />
            </Suspense>
            
            <div className="site-main">
                <Suspense fallback={<div></div>}>
                    <MainPageItemLazy />
                </Suspense>
            </div>
        </>
    );
}

const Component = React.memo(Home, ( prevProps:THome<PropsFromRedux>, nextProps:THome<PropsFromRedux> ) => {
    console.log("Home.memo", { prevProps, nextProps });
    return true;
});

export default connector(Component);
