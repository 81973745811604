import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import { Link, useSearchParams } from "react-router-dom";
// components
import ContentHeaderRow from "components/ContentHeaderRow";
import RMDRImage from "components/RMDRImage";
import SEO from "components/SEO";
import NoData from "components/NoData";
import Pagination from "components/Pagination";
// actions
import CoreAction from "core/actions/common/CoreAction";
import ClientPagesServiceAction from "cms/actions/components/ShowroomLayouts/ClientPagesServiceAction";
import ShowroomLayoutsAction from "cms/actions/components/ShowroomLayouts/ShowroomLayoutsAction";
// interfaces
import IOdataQueryOptions from "core/interfaces/IOdataQueryOptions";
import IClientPageList from "cms/interfaces/IClientPageList";
import IClientLanguage from "core/interfaces/IClientLanguage";
import ILanguageVersionDetail from "cms/interfaces/ILanguageVersionDetail";
import ILanguageVersionList from "cms/interfaces/ILanguageVersionList";
// enums
import { EView } from "cms/enums/EView";
import { EViewType } from "cms/enums/EViewType";
import { EResourceType } from "cms/enums/EResourceType";
import { EContentType } from "cms/enums/EContentType";
import { EContentStatus } from "cms/enums/EContentStatus";
// utilities
import LanguageVersionSelector from "cms/utilities/LanguageVersionSelector";
// types
import TValidations from "cms/types/TValidations";
import { TSHWRMLayout } from "../type";
import { isEmpty } from "lodash";
import { HiOutlineNewspaper } from "react-icons/hi2";
import IClientSiteSettingDetail from "cms/interfaces/IClientSiteSettingDetail";
import RMDRLink from "components/RMDRLink";
import { ELayoutType } from "cms/enums/ELayoutType";
import ERedirectType from "cms/enums/ERedirectType";


function mapStateToProps ( state: any ) {
    console.log("SHWRM002.mapStateToProps: ", state);

    const webApplicationLanguage:IClientLanguage    = state.CoreStore.language?.payload;
    const webApplicationLanguageCode:string         = webApplicationLanguage?.code;
    const webApplicationLanguageISOCode:string      = state.CoreStore.languageCode?.payload;

    const siteSettingDetailPayload:IClientSiteSettingDetail = state.SiteSettingStore.details?.payload;
    const searchPagePayload:any                             = state.ShowroomLayoutsStore.SearchPageStore.payload;

    const pageListPayload:IClientPageList[]|null      = state.ShowroomLayoutsStore.ClientPagesStore.list.payload;
    const pageListCount:number                        = state.ShowroomLayoutsStore.ClientPagesStore.list?.count;
    const pageListError:TValidations[]|never          = state.ShowroomLayoutsStore.ClientPagesStore.list.error;
    const pageListIsLoading:boolean                   = state.ShowroomLayoutsStore.ClientPagesStore.list.isLoading;

    return {

        webApplicationLanguage,
        webApplicationLanguageCode,
        webApplicationLanguageISOCode,

        siteSettingDetailPayload,
        searchPagePayload,

        pageListPayload,
        pageListCount,
        pageListError,
        pageListIsLoading,

    }

}

function mapDispatchToProps ( dispatch: Dispatch ) {

    const coreAction = new CoreAction(dispatch);
    const clientPagesServiceAction = new ClientPagesServiceAction(dispatch);
    const showroomLayoutsAction = new ShowroomLayoutsAction(dispatch);

    return {
        _modifySearchPageStore: ( payload:any ) => {
            showroomLayoutsAction.modify(payload);
        },
        _setSearchInit: ({searchString, orderBy, itemsPerPage, currentPage, hasSearch}:any) => {
            showroomLayoutsAction.modify([
                {key:"searchString", value: searchString},
                {key:"orderBy", value: orderBy},
                {key:"itemsPerPage", value: itemsPerPage},
                {key:"currentPage", value: currentPage},
                {key:"hasSearch", value: hasSearch},
                {key:"hasFirstSearch", value: true},
                {key:"scrollTo", value: true},
            ]);
        },
        _setSearchString: ( value:string ) => {
            showroomLayoutsAction.modify([
                {key:"searchString", value: value}
            ]);
        },
        _setHasSearch: ( value:boolean = true, isNewSearch:boolean = false ) => {
            let _payload:any = [
                {key:"hasSearch", value},
            ]
            if (isNewSearch) {
                _payload = [
                    {key:"orderBy", value: "order asc"},
                    {key:"currentPage", value: 1},
                    {key:"hasFirstSearch", value: true},
                    {key:"scrollTo", value: true},
                    ..._payload
                ]
            }
            
            showroomLayoutsAction.modify(_payload);
        },
        _setFirstSearch: ( value:boolean ) => {
            showroomLayoutsAction.modify([
                {key:"hasFirstSearch", value}
            ]);
        },
        _setScrollTo: ( value:boolean ) => {
            showroomLayoutsAction.modify([
                {key:"scrollTo", value},
            ]);
        },
        _setLastPage: ( lastPage:number ) => {
            showroomLayoutsAction.modify([
                {key:"lastPage", value: lastPage},
            ]);
        },
        _setCurrentPage: ( currentPage:number ) => {
            showroomLayoutsAction.modify([
                {key:"currentPage", value: currentPage},
                {key:"hasSearch", value: true},
                {key:"scrollTo", value: true},
            ]);
        },
        _search: ( payload:any, webApplicationLanguageCode:string ) => {
            let queryOptions:IOdataQueryOptions = {
                filter: [`status eq ${EContentStatus.ACTIVE}`],
                count: true,
                top: payload.itemsPerPage,
                skip: (payload.currentPage-1) * payload.itemsPerPage
            };
            
            if(!isEmpty(payload.filters) && payload.filters) {
                queryOptions.filter?.push("and");
                queryOptions.filter = [...queryOptions?.filter||[], ...payload.filters];
            }

            if ( payload.orderBy && !isEmpty(payload.orderBy) ) {
                queryOptions["orderBy"] = [payload.orderBy]
            }

            clientPagesServiceAction.list({ servicePayload: { queryOptions, language: webApplicationLanguageCode } });
        },

        // others
        _showLoader: () => {
            coreAction.showLoader()
        },

        _hideLoader: () => {
            coreAction.hideLoader()
        },

        _clean: () => {
            clientPagesServiceAction.listClean();
            showroomLayoutsAction.clean();
        }
    }

}

const connector = connect(mapStateToProps, mapDispatchToProps);

/* types */
type PropsFromRedux = ConnectedProps<typeof connector>


/* component */
function Loader () {

    return (
        <>
            {
                Array.from({ length:9 }).map(() => (
                    <div className="tw-w-1/3 tw-p-1">
                        <div className="tw-animate-pulse tw-bg-gray-100 tw-h-48" />
                    </div>
                ))
            }
        </>
    )

}

function SHWRM002 ( props:TSHWRMLayout<PropsFromRedux> ) {
    console.log("SHWRM002.rendered: ", props);

    const [ searchParams, setSearchParams ] = useSearchParams();

    const [ init ] = useState([
        {key:"orderBy", value: "order asc"},
        {key:"itemsPerPage", value: 9},
        {key:"currentPage", value: 1},
        {key:"scrollTo", value: true},
        {key:"hasSearch", value: true},
    ]);

    const languageVersion = LanguageVersionSelector<ILanguageVersionDetail|null>({
        languageVersions: props.contentDetailPayload.languageVersions,
        filters: [
            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.CATEGORY },
            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.PAGE },
            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }
        ],
        type:"find"
    });

    useEffect(
        () => {
            return function cleanup () {
                props._showLoader();
                props._clean();
            }
        }, []
    )

    useEffect(
        () => {
            let filters: string[] = [];

            let _currentPage = parseInt(searchParams.get("cp")?.trim()||"1");
            if ( isNaN(_currentPage) ) {
                _currentPage = 1;
            }

            filters.push(`contentId eq ${props.contentDetailPayload.id}`);

            let _init = [
                ...init,
                { key:"filters", value: filters },
                { key:"currentPage", value: _currentPage },
            ];
            props._modifySearchPageStore(_init);
        }, [ searchParams ]
    )

    useEffect(
        () => {
            if (props.searchPagePayload?.hasSearch) {
                props._setHasSearch(false);
                props._search(props.searchPagePayload, props.webApplicationLanguageCode);
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            }
        }, [props.searchPagePayload]
    )

    useEffect(
        () => {
            // if (props.clientPageListPayload?.scrollTo) {
                // setTimeout(
                //     () => {
                //         window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                //     }, 200
                // );
                props._setFirstSearch(false);
                // props._setScrollTo(false);

                setTimeout(() => { props._hideLoader() }, 100);
            // }
        }, [props.pageListPayload]
    )

    useEffect(
        () => {
            try {
                let _lastPage = Math.ceil(props.pageListCount/props.searchPagePayload.itemsPerPage);
                props._setLastPage(_lastPage);
            }
            catch(ex) {}
        }, [props.pageListCount]
    )

    return (
        <div className="site-main">
            
            <SEO siteSettingPayload={props.siteSettingDetailPayload} type="showroom-page" />

            <ContentHeaderRow 
                title={languageVersion?.name} 
                description={languageVersion?.value} 
                resources={props.contentDetailPayload.resources}
                resourceType={EResourceType.PARALLAX }
            />

            <section className="ttm-row res-991-pt-0 clearfix">
                <div className="container-xl">
                    <div className="row multi-columns-row ttm-boxes-spacing-10px ttm-bgcolor-white !tw-mt-12">

                        {
                            !props.pageListIsLoading && isEmpty(props.pageListPayload) ? (
                                <NoData
                                    icon={<HiOutlineNewspaper className="tw-h-40 tw-stroke-1 tw-text-9xl" />}
                                    className="tw-flex tw-flex-col tw-items-center tw-justify-center"
                                    textClassName="tw-text-center tw-text-xl"
                                    languageVersions={props.siteSettingDetailPayload.languageVersions}
                                />
                            ) : null
                        }

                        {   
                            !props.pageListIsLoading && !isEmpty(props.pageListPayload) ?
                                (
                                    props.pageListPayload?.map((item) => {
                                        
                                        const languageVersion = LanguageVersionSelector<ILanguageVersionList|undefined>({
                                            languageVersions: item.languageVersions,
                                            filters: [
                                                { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }
                                            ],
                                            type: "find"
                                        });

                                        const redirectUrl =  LanguageVersionSelector<ILanguageVersionList|undefined>({
                                            languageVersions: item.languageVersions,
                                            filters: [
                                                { view: EView.META_DATA, viewType: EViewType.TEXT, name: "redirectURL" }
                                            ],
                                            type: "find"
                                        });

                                        const layoutName = item.layouts.find((item) => [ELayoutType.INNER_PAGE, ELayoutType.SHOWROOM, ELayoutType.GALLERY].includes(item.layoutType))

                                        const hasDestinationNavigation = !isEmpty(layoutName) || !isEmpty(redirectUrl);

                                        return (
                                            <div key={item.id} className="ttm-box-col-wrapper col-lg-4 col-md-6 col-sm-6">
                                                <div className="featured-imagebox featured-imagebox-portfolio style2">
                                                    <div className="featured-thumbnail tw-text-center">
                                                        <RMDRImage
                                                            resourceType={EResourceType.OUTER_IMAGE} 
                                                            resources={item.resources} 
                                                            alt={languageVersion?.name} 
                                                            title={languageVersion?.name} 
                                                            className="img-fluid tw-h-32 tw-w-auto"
                                                        />
                                                    </div>
                                                    <div className="featured-content">
                                                        <div className="category">
                                                            {/* <p>Business , Campaign</p> */}
                                                        </div>
                                                        <div className="featured-title">
                                                            <h5>
                                                                <RMDRLink
                                                                    title={languageVersion?.name}
                                                                    text={languageVersion?.name}
                                                                    hasNavigationDestination={hasDestinationNavigation}
                                
                                                                    navigationURL={languageVersion?.contentRouteNavigationURL}
                                
                                                                    redirectType={ERedirectType.REDIRECT_NEW_TAB}
                                                                    redirectUrl={redirectUrl?.value}
                                                                    isShowAlways
                                                                />
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )

                                    })
                                ) : null
                        }

                        {
                            props.pageListIsLoading?
                                (
                                    <Loader />
                                ) : null
                        }

                    </div>
                </div>
            </section>

            {
                props.searchPagePayload.lastPage > 1 &&
                    <Pagination pageSize={props.searchPagePayload.lastPage} disabled={props.pageListIsLoading} className="tw-mb-28" />
            }

        </div>            
    )
}

const Component = React.memo(SHWRM002, ( prevProps:TSHWRMLayout<PropsFromRedux>, nextProps:TSHWRMLayout<PropsFromRedux> ) => {
    console.log("SHWRM002.memo", { prevProps, nextProps });
    return !(
        prevProps.searchPagePayload !== nextProps.searchPagePayload
        || prevProps.pageListPayload !== nextProps.pageListPayload
    )
});

export default connector(Component);
