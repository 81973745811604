import isEmpty from "lodash/isEmpty";
import orderBy from "lodash/orderBy";
// components
import RMDRTextContent from "components/RMDRTextContent";
import RMDRAccordion01 from "components/RMDRAccordions/components/RMDRAccordion01";
import RMDRTab04 from "components/RMDRTabs/components/RMDRTab03";
// enums
import { EView } from 'cms/enums/EView';
import { EContentType } from 'cms/enums/EContentType';
// utilities
import LanguageVersionSelector from "cms/utilities/LanguageVersionSelector";
// interfaces
import ILanguageVersionList from "cms/interfaces/ILanguageVersionList";
import ILanguageVersionDetail from "cms/interfaces/ILanguageVersionDetail";
import IDetailLanguageVersion from "cms/interfaces/ILanguageVersionDetail"
import IListLanguageVersion from "cms/interfaces/ILanguageVersionList"


type TContentRenderer = {
    
    languageVersions?: IListLanguageVersion[]|IDetailLanguageVersion[]|null;

}

function ContentRenderer ( props:TContentRenderer ) {

    if ( props.languageVersions && !isEmpty(props.languageVersions) ) {

        let parentLanguageVersions = LanguageVersionSelector<ILanguageVersionList[]|ILanguageVersionDetail[]|null>({
            languageVersions: props.languageVersions,
            filters: [
                { view: EView.INNER, contentType: EContentType.DEFAULT, customFilter:[ { key: "parentId", value: null } ] }
            ],
            type: 'filter'
        });

        parentLanguageVersions = orderBy(parentLanguageVersions, ['order'],['asc']);

        return (
            <>
                {
                    parentLanguageVersions.map(
                        (item) => <>
                            <RMDRTextContent parentLanguageVersion={item} languageVersions={props.languageVersions} />
                            <RMDRAccordion01 parentLanguageVersion={item} languageVersions={props.languageVersions} />
                            <RMDRTab04 parentLanguageVersion={item} languageVersions={props.languageVersions} />
                        </> 
                    )
                }
            </>
        )

    }

    return null;

}

export default ContentRenderer;
export type {
    TContentRenderer
};