import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import CoreDefault from "core/Defaults";
// components
import RMDRTextContent from "components/RMDRTextContent";
import RMDRImage from "components/RMDRImage";
import { MdContactPhone, MdEmail } from "react-icons/md";
// actions
import CoreAction from "core/actions/common/CoreAction";
// enums
import { EView } from "cms/enums/EView";
import { EContentType } from "cms/enums/EContentType";
import { EViewType } from "cms/enums/EViewType";
import { EResourceType } from "cms/enums/EResourceType";
// utilities
import LanguageVersionSelector from "cms/utilities/LanguageVersionSelector";
// interfaces
import ILanguageVersionDetail from "cms/interfaces/ILanguageVersionDetail";
import IClientLanguage from "core/interfaces/IClientLanguage";
import IClientSiteSettingDetail from "cms/interfaces/IClientSiteSettingDetail";
// types
import { TINNRLayout } from "components/InnerLayouts/type";
import ContactUsV2 from "components/ContactUsV2";


function mapStateToProps ( state: any ) {
    console.log("INNR004.mapStateToProps: ", state);

    const webApplicationLanguage:IClientLanguage    = state.CoreStore.language?.payload;
    const webApplicationLanguageCode:string         = webApplicationLanguage?.code;
    const webApplicationLanguageISOCode:string      = state.CoreStore.languageCode?.payload;

    const siteSettingDetailPayload:IClientSiteSettingDetail    = state.SiteSettingStore.details?.payload;

    return {

        webApplicationLanguage,
        webApplicationLanguageCode,
        webApplicationLanguageISOCode,
        
        siteSettingDetailPayload,

    }
}

function mapDispatchToProps(dispatch: Dispatch) {

    const coreAction = new CoreAction(dispatch);

    return {

        _showLoader: () => { 
            coreAction.showLoader() 
        },

        _hideLoader: () => { 
            coreAction.hideLoader() 
        }
    }

}

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>

function INNR004(props: TINNRLayout<PropsFromRedux>) {
    console.log("INNR004.rendered: ", props);

    const languageVersion = LanguageVersionSelector<ILanguageVersionDetail|undefined>({
        languageVersions: props.contentDetailPayload?.languageVersions,
        filters: [
            { view: EView.INNER, viewType: EViewType.TEXT, contentType: EContentType.DEFAULT }
        ],
        type: "find"
    });

    const phoneNumber = LanguageVersionSelector<ILanguageVersionDetail|undefined>({
        languageVersions: props.contentDetailPayload?.languageVersions,
        filters: [
            { view: EView.META_DATA, viewType: EViewType.TEXT, contentType: EContentType.DEFAULT, name: "phoneNumber" }
        ],
        type: "find"
    });

    const emailAddress = LanguageVersionSelector<ILanguageVersionDetail|undefined>({
        languageVersions: props.contentDetailPayload?.languageVersions,
        filters: [
            { view: EView.META_DATA, viewType: EViewType.TEXT, contentType: EContentType.DEFAULT, name: "email" }
        ],
        type: "find"
    });

    const title = LanguageVersionSelector<ILanguageVersionDetail|undefined>({
        languageVersions: props.contentDetailPayload?.languageVersions,
        filters: [
            { view: EView.META_DATA, viewType: EViewType.TEXT, contentType: EContentType.DEFAULT, name: "title" }
        ],
        type: "find"
    });

    const addContact = LanguageVersionSelector<ILanguageVersionDetail|undefined>({
        languageVersions: props.contentDetailPayload?.languageVersions,
        filters: [
            { view: EView.META_DATA, viewType: EViewType.TEXT, contentType: EContentType.DEFAULT, name: "addContact" }
        ],
        type: "find"
    });

    useEffect(
        () => {
            setTimeout(() => { props._hideLoader(); }, 100);

            return () => {
                props._showLoader();
            }

        }, []
    )

    const vcardDocumentURL = props.contentDetailPayload.resources?.find((item) => item.resourceTypeId === EResourceType.DATA)?.applicationDocument;

    // async function fetchVCard() {
    //     console.log("fetchVCard")

    //     const vcardDocumentURL = props.contentDetailPayload.resources?.find((item) => item.resourceTypeId === EResourceType.DATA)?.applicationDocument;

    //     console.log("fetchVCard: ", vcardDocumentURL);

    //     if ( vcardDocumentURL && !isEmpty(vcardDocumentURL) ) {
    //         const vCardBlob = await fetch(`${CoreDefault.cdn}/${vcardDocumentURL?.filePath}`).then(r => r.text());
    //         console.log("fetchVCard: ", vcardDocumentURL?.filePath);
    //         setVCard(vCardBlob);
    //     }
        
    // }

    // function downloadVCF(event:React.SyntheticEvent) { 
    //     if ( vCard ) {
    //         const element = document.createElement("a");
    //         const file = new Blob([vCard], { type: "text/x-vcard;charset=utf-8" });
    //         element.href = URL.createObjectURL(file);
    //         element.download = `${languageVersion?.name}.vcf`;
    //         document.body.appendChild(element);
    //         element.click();
    //     }
    // }

    return (
        <section className="ttm-row team-details-section clearfix">
            <div className="container-xl">
                <div className="row">

                    <div className="col-lg-4 tw-">
                        <div className="ttm-featured-wrapper">
                            <div className="featured-thumbnail text-center">
                                <a href={`${CoreDefault.cdn}/${vcardDocumentURL?.filePath}`} >
                                    <RMDRImage
                                        resourceType={EResourceType.INNER_IMAGE}
                                        resources={props.contentDetailPayload?.resources} 
                                        alt={languageVersion?.name}
                                        title={languageVersion?.name}
                                        className="img-fluid"
                                    />
                                </a>
                            </div>
                        </div>
                        <div className="">
                            <a href={`${CoreDefault.cdn}/${vcardDocumentURL?.filePath}`} className="ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor tw-w-full !tw-text-base tw-text-center">
                                <i className="fa fa-user" />
                                {addContact?.value}
                            </a>
                        </div>
                    </div>

                    <div className="col-lg-8">
                        <div className="ttm-team-member-content shadow-box res-991-mt-30">
                            <div className="ttm-team-member-single-list">
                                <h2 className="ttm-team-member-single-title">{languageVersion?.name}</h2>
                                <span className="ttm-team-member-single-position">{title?.value}</span>
                                <RMDRTextContent parentLanguageVersion={languageVersion} />
                                
                                <div className="separator">
                                    <div className="sep-line mt-4 mb-4" />
                                </div>

                                {
                                    (phoneNumber?.value || emailAddress?.value) && (
                                        <>
                                            <div className="ttm-team-data">
                                                <div className="ttm-team-details-wrapper">
                                                    <ul className="ttm-team-details-list clearfix">
                                                        {
                                                            phoneNumber?.value && (
                                                                <li>
                                                                    <div className="ttm-team-list-title"><MdContactPhone /></div>
                                                                    <div className="ttm-team-list-value"><a className="tw-no-underline" href={`tel:${phoneNumber?.value||""}`}>{phoneNumber?.value}</a></div>
                                                                </li>
                                                            )
                                                        }
                                                        {
                                                            emailAddress?.value && (
                                                                <li>
                                                                    <div className="ttm-team-list-title"><MdEmail /></div>
                                                                    <div className="ttm-team-list-value"><a className="tw-no-underline" href={`mailto:${emailAddress?.value}`}>{emailAddress?.value}</a></div>
                                                                </li>
                                                            )
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="separator">
                                                <div className="sep-line mt-3 mb-4" />
                                            </div>
                                        </>
                                    )
                                }
                                
                            </div>
                        </div>
                    </div>

                </div>
                
                <div className="row tw-mt-10">
                    <div className="col-12">
                        <div className="member-contact-form border res-991-mt-30">
                            <ContactUsV2 />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );

}

const Component = React.memo(INNR004, (prevProps: TINNRLayout<PropsFromRedux>, nextProps: TINNRLayout<PropsFromRedux>) => {
    console.log("INNR004.memo", { prevProps, nextProps });
    return true;
});

export default connector(Component);
