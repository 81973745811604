import React, { useState, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
// actions
import CoreAction from "core/actions/common/CoreAction";// components
import ContentHeaderRow from "components/ContentHeaderRow";
// interfaces
import IClientLanguage from "core/interfaces/IClientLanguage";
import IClientSiteSettingDetail from "cms/interfaces/IClientSiteSettingDetail";
// enums
import { EView } from "cms/enums/EView";
import { EViewType } from "cms/enums/EViewType";
import { EContentType } from "cms/enums/EContentType";
import { EResourceType } from "cms/enums/EResourceType";
// type
import { TINNRLayout } from "../type";
// utilities
import LanguageVersionSelector from "cms/utilities/LanguageVersionSelector";
import ILanguageVersionDetail from "cms/interfaces/ILanguageVersionDetail";
import ContactUs from "components/ContactUs";


function mapStateToProps ( state: any ) {
    console.log("INNR003.mapStateToProps: ", state);

    const webApplicationLanguage:IClientLanguage    = state.CoreStore.language?.payload;
    const webApplicationLanguageCode:string         = webApplicationLanguage?.code;
    const webApplicationLanguageISOCode:string      = state.CoreStore.languageCode?.payload;

    const siteSettingDetailPayload:IClientSiteSettingDetail    = state.SiteSettingStore.details?.payload;

    return {

        webApplicationLanguage,
        webApplicationLanguageCode,
        webApplicationLanguageISOCode,
        
        siteSettingDetailPayload,

    }
}

function mapDispatchToProps ( dispatch: Dispatch ) {
    console.log("INNR003.mapDispatchToProps");

    const coreAction = new CoreAction(dispatch);

    return {
        _showLoader: () => { 
            coreAction.showLoader() 
        },
        _hideLoader: () => { 
            coreAction.hideLoader() 
        },
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>

const INNR003 = ( props:TINNRLayout<PropsFromRedux> ) => {
    console.log("INNR003.rendered: ", props);
    
    const [ verboseResources, setVerboseResources ] = useState<any>({});

    useEffect(
        () => {
            // string Values
            const comeVisitUsAtString = LanguageVersionSelector<ILanguageVersionDetail|undefined>({ languageVersions: props.contentDetailPayload.languageVersions, filters: [{ view: EView.META_DATA, viewType: EViewType.TEXT, name: "comeVisitUsAtString" }], type: 'find' });
            const ourAddressString = LanguageVersionSelector<ILanguageVersionDetail|undefined>({ languageVersions: props.contentDetailPayload.languageVersions, filters: [{ view: EView.META_DATA, viewType: EViewType.TEXT, name: "ourAddressString" }], type: 'find' });
            const officeAddressString = LanguageVersionSelector<ILanguageVersionDetail|undefined>({ languageVersions: props.contentDetailPayload.languageVersions, filters: [{ view: EView.META_DATA, viewType: EViewType.TEXT, name: "officeAddressString" }], type: 'find' });
            const ourPhoneNumberString = LanguageVersionSelector<ILanguageVersionDetail|undefined>({ languageVersions: props.contentDetailPayload.languageVersions, filters: [{ view: EView.META_DATA, viewType: EViewType.TEXT, name: "ourPhoneNumberString" }], type: 'find' });
            const ourEmailString = LanguageVersionSelector<ILanguageVersionDetail|undefined>({ languageVersions: props.contentDetailPayload.languageVersions, filters: [{ view: EView.META_DATA, viewType: EViewType.TEXT, name: "ourEmailString" }], type: 'find' });

            // values
            const languageVersion = LanguageVersionSelector<ILanguageVersionDetail|undefined>({ languageVersions: props.contentDetailPayload.languageVersions, filters: [{ view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }, { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.PAGE }, { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.CATEGORY }], type: 'find' });
            const googleMaps = LanguageVersionSelector<ILanguageVersionDetail|undefined>({ languageVersions: props.contentDetailPayload.languageVersions, filters: [{ view: EView.META_DATA, viewType: EViewType.TEXT, name: "googleMaps" }], type: 'find' });
            const phoneNumber = LanguageVersionSelector<ILanguageVersionDetail|undefined>({ languageVersions: props.siteSettingDetailPayload.languageVersions, filters: [{ view: EView.META_DATA, viewType: EViewType.TEXT, name: "phoneNumber" }], type: 'find' });
            const address = LanguageVersionSelector<ILanguageVersionDetail|undefined>({ languageVersions: props.siteSettingDetailPayload.languageVersions, filters: [{ view: EView.META_DATA, viewType: EViewType.TEXT, name: "address" }], type: 'find' });
            const email = LanguageVersionSelector<ILanguageVersionDetail|undefined>({ languageVersions: props.siteSettingDetailPayload.languageVersions, filters: [{ view: EView.META_DATA, viewType: EViewType.TEXT, name: "email" }], type: 'find' });

            setVerboseResources({
                comeVisitUsAtString, ourAddressString, officeAddressString, ourPhoneNumberString, ourEmailString,
                languageVersion, googleMaps, phoneNumber, address, email,
            });
            
            setTimeout(() => { props._hideLoader(); }, 100);

            return () => {
                props._showLoader();
            }

        }, []
    )

    return (
        <>
            <ContentHeaderRow 
                title={verboseResources.languageVersion?.name} 
                description={verboseResources.languageVersion?.value} 
                resources={props.contentDetailPayload.resources} 
                resourceType={EResourceType.PARALLAX}
            />

            <section className="ttm-row zero-padding-section clearfix">
                <div className="container-xl">
                    <div className="row no-gutters">

                        <div className="col-lg-5">
                            <div className="spacing-9">
                                <div className="section-title with-desc clearfix">
                                    <div className="title-header">
                                        <h5>{ verboseResources.comeVisitUsAtString?.value }</h5>
                                        <h2 className="title">{ verboseResources.ourAddressString?.value }</h2>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="featured-icon-box style2 left-icon icon-align-top">
                                            <div className="featured-icon">
                                                <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                                                    <i className="ti-location-pin" />
                                                </div>
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title">
                                                    <h5>{ verboseResources.officeAddressString?.value }</h5>
                                                </div>
                                                <div className="featured-desc">
                                                    <p>{ verboseResources.address?.value }</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="separator">
                                    <div className="sep-line mt-4 mb-4" />
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="featured-icon-box style2 left-icon icon-align-top">
                                            <div className="featured-icon">
                                                <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                                                    <i className="fa fa-phone" />
                                                </div>
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title">
                                                    <h5>{ verboseResources.ourPhoneNumberString?.value }</h5>
                                                </div>
                                                <div className="featured-desc">
                                                    <p><a className="tw-no-underline" href={`tel:${verboseResources.phoneNumber?.value}`}>{ verboseResources.phoneNumber?.value }</a></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="separator">
                                    <div className="sep-line mt-4 mb-4" />
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="featured-icon-box style2 left-icon icon-align-top">
                                            <div className="featured-icon">
                                                <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                                                    <i className="ti ti-email" />
                                                </div>
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title">
                                                    <h5>{ verboseResources.ourEmailString?.value }</h5>
                                                </div>
                                                <div className="featured-desc">
                                                    <p><a className="tw-no-underline" href={`mailto: ${verboseResources.email?.value}`}>{ verboseResources.email?.value }</a></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-7">
                            <ContactUs />
                        </div>

                    </div>
                </div>
            </section>

            {
                verboseResources.googleMaps?.value &&
                    <section className="ttm-row map-section res-991-p-0 clearfix">
                        <div className="map-wrapper">
                            <iframe name="googleMaps" title="googleMaps" height="100%" src={verboseResources.googleMaps?.value} />
                        </div>
                    </section>
            }
            
        </>
    )
}

const Component = React.memo(INNR003, ( prevProps:TINNRLayout<PropsFromRedux>, nextProps:TINNRLayout<PropsFromRedux> ) => {
    console.log("INNR003.memo", { prevProps, nextProps });
    return true;
});

export default connector(Component);
