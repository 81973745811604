// components
import RMDRImage from "components/RMDRImage";
// types
import { TFooterComponent } from "../type";
// enums
import { EResourceType } from "cms/enums/EResourceType";
import ApplicationDocumentSelector from "cms/utilities/ApplicationDocumentSelector";
import CoreDefault from "core/Defaults";
import useProgressiveImage from "core/hooks/useProgressiveImage";
import IApplicationDocument from "cms/interfaces/IApplicationDocument";
import LanguageVersionSelector from "cms/utilities/LanguageVersionSelector";
import ILanguageVersionDetail from "cms/interfaces/ILanguageVersionDetail";
import { EContentType } from "cms/enums/EContentType";
import { EViewType } from "cms/enums/EViewType";
import { EView } from "cms/enums/EView";
import ESizeType from "cms/enums/ESizeType";


const TopBar = ( props:TFooterComponent ) => {
    console.log("TopBar.rendered: ", props);

    const siteSetting = props.mainPageItemsPayload?.find((item) => item.parentId === props.payload.id && item.contentType === EContentType.SITE_SETTING);

    const languageVersion = LanguageVersionSelector<ILanguageVersionDetail|undefined>({
        languageVersions: siteSetting?.languageVersions,
        filters: [
            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }
        ],
        type: "find"
    });

    const bgImage = ApplicationDocumentSelector<IApplicationDocument|null>({
        resources: props.payload.resources,
        resourceType: EResourceType.PARALLAX,
        type: "find"
    });
    const loaded = useProgressiveImage({ src:bgImage?.filePath?`${CoreDefault.cdn}/${bgImage?.filePath}`:null });
    
    return (
        <div className="first-footer ttm-bgcolor-skincolor ttm-bg ttm-bgimage-yes" style={loaded?{backgroundImage: `url('${loaded}')`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover"}:undefined}>
            <div className="ttm-row-wrapper-bg-layer ttm-bg-layer" />

            <div className="container-xl">
                <div className="row align-items-md-center">
                    <div className="footer-logo text-center">
                        <RMDRImage
                            resourceType={EResourceType.IMAGE} 
                            resources={siteSetting?.resources}
                            sizeType={ESizeType.X06}
                            alt={languageVersion?.name}
                            title={languageVersion?.name} 
                            className="img-fluid"
                        />
                    </div>
                </div>
            </div>
            
        </div>
    )
    
}

export default TopBar;
