import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import clsx from "clsx";
import { Dispatch } from "@reduxjs/toolkit";
// components
import { FaWhatsapp, FaFacebookMessenger, FaUser } from "react-icons/fa";
// types
import { TMainPageLayout } from "../type";
// enums
import { EView } from "cms/enums/EView";
import { EViewType } from "cms/enums/EViewType";
// interfaces
import IClientLanguage from "core/interfaces/IClientLanguage";
import IClientSiteSettingDetail from "cms/interfaces/IClientSiteSettingDetail";
import ILanguageVersionList from "cms/interfaces/ILanguageVersionList";
import IClientMainPageItemList from "cms/interfaces/IClientMainPageItemList";
// utilities
import LanguageVersionSelector from "cms/utilities/LanguageVersionSelector";
import SanitizeHTMLString from "cms/utilities/SanitizeHTMLString";
import { EResourceType } from "cms/enums/EResourceType";
import ApplicationDocumentSelector from "cms/utilities/ApplicationDocumentSelector";
import IApplicationDocument from "cms/interfaces/IApplicationDocument";
import { EContentType } from "cms/enums/EContentType";
import RMDRImage from "components/RMDRImage";


function mapStateToProps ( state: any ) {
    console.log("MNPG013.mapStateToProps: ", state);

    const webApplicationLanguage:IClientLanguage    = state.CoreStore.language?.payload;
    const webApplicationLanguageCode:string         = webApplicationLanguage?.code;
    const webApplicationLanguageISOCode:string      = state.CoreStore.languageCode?.payload;

    const siteSettingDetailPayload:IClientSiteSettingDetail = state.SiteSettingStore.details?.payload;
    
    const mainPageItemsListPayload:IClientMainPageItemList[]   = state.MainPageItemStore.ClientMainPageItemsStore.list?.payload;

    return {

        webApplicationLanguage,
        webApplicationLanguageCode,
        webApplicationLanguageISOCode,

        siteSettingDetailPayload,

        mainPageItemsListPayload
    
    }
}

function mapDispatchToProps ( dispatch: Dispatch ) {
    console.log("MNPG013.mapDispatchToProps");
    
    return {}
}

const connector = connect(mapStateToProps, mapDispatchToProps);


/* types */
type PropsFromRedux = ConnectedProps<typeof connector>
type TUserTemplate = {

    isMessenger: boolean;
    isWhatsApp: boolean;

    workingHoursStart?: string|null;
    workingHoursEnd?: string|null;

}

// functions
const isUserActive = ( workingHoursStart?:string|null, workingHoursEnd?:string|null ) => {

    let date = new Date();

    let startHours      = new Date(`2000-01-01T${workingHoursStart}:00Z`);
    let currentHours    = new Date(`2000-01-01T${String(date.getHours()).padStart(2,"0")}:${String(date.getMinutes()).padStart(2,"0")}:00Z`);
    let endHours        = new Date(`2000-01-01T${workingHoursEnd}:00Z`);

    return currentHours > startHours && currentHours < endHours;

}

/* component */
const UserTemplate = (props:TMainPageLayout<TUserTemplate>) => {
    
    const [ userStatus, setUserStatus ] = useState<boolean>(false);

    useEffect(
        () => {
            let intervalId:any = null;
            if ( props.workingHoursStart && props.workingHoursEnd ) {
                setUserStatus(isUserActive(props.workingHoursStart, props.workingHoursEnd))
                intervalId = setInterval(
                    () => { setUserStatus(isUserActive(props.workingHoursStart, props.workingHoursEnd))}, 5000
                )
            } else {
                setUserStatus(true);
            }

            return () => {
                if ( intervalId ) clearInterval(intervalId);
            }
        }, []
    )

    return (
        <>
            {
                props.childsPayload?.map((item) => {
                    const languageVersions = LanguageVersionSelector<ILanguageVersionList|null>({
                        languageVersions: item.languageVersions,
                        filters:[{ view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }],
                        type: "find"
                    });

                    const userTitle = LanguageVersionSelector<ILanguageVersionList|null>({
                        languageVersions: item.languageVersions,
                        filters:[{ view: EView.META_DATA, name: "title" }],
                        type: "find"
                    });

                    let _href = "#";
                    if ( props.isWhatsApp && userStatus ) {
                        const phoneNumber = LanguageVersionSelector<ILanguageVersionList|null>({
                            languageVersions: item.languageVersions,
                            filters:[{ view: EView.META_DATA, name: "whatsAppPhoneNumber" }],
                            type: "find"
                        });
                        _href = `https://wa.me/${phoneNumber?.value}`;
                    
                    }
                    else if ( props.isMessenger && userStatus ) {
                        const messengerId = LanguageVersionSelector<ILanguageVersionList|null>({
                            languageVersions: item.languageVersions,
                            filters:[{ view: EView.META_DATA, name: "messengerId" }],
                            type: "find"
                        });
                        _href = `https://m.me/${messengerId?.value}`;
                    }

                    const isOuterImage = ApplicationDocumentSelector<IApplicationDocument|null>({ 
                        resources: item.resources,
                        resourceType: EResourceType.OUTER_IMAGE,
                        type: "find"
                    }) !== null;

                    const isInnerImage = ApplicationDocumentSelector<IApplicationDocument|null>({ 
                        resources: item.resources,
                        resourceType: EResourceType.INNER_IMAGE,
                        type: "find"
                    }) !== null;

                    return (
                        <a href={_href} className={clsx("hover:tw-text-gray-900", { "hover:tw-text-whatsapp":props.isWhatsApp && userStatus }, { "hover:tw-text-messenger":props.isMessenger && userStatus })} title={languageVersions?.name||""}>
                            <div className={clsx("tw-flex tw-p-4 tw-shadow-md tw-bg-gray-100 hover:tw-bg-gray-50 tw-border-2 tw-rounded-md tw-border-t-0 tw-border-b-0 tw-border-r-0",{ "tw-border-whatsapp tw-border-solid":props.isWhatsApp && userStatus }, { "tw-border-messenger tw-border-solid":props.isMessenger && userStatus })}>
                                <div className="tw-pr-4">
                                    { 
                                        isOuterImage && (
                                            <RMDRImage
                                                resources={item.resources}
                                                resourceType={EResourceType.OUTER_IMAGE}
                                                title={languageVersions?.name}
                                                alt={languageVersions?.name}
                                                className="tw-rounded-full tw-w-12 tw-h-12"
                                            />
                                        )
                                    }
                                    { 
                                        isInnerImage && (
                                            <RMDRImage
                                                resources={item.resources}
                                                resourceType={EResourceType.INNER_IMAGE}
                                                title={languageVersions?.name}
                                                alt={languageVersions?.name}
                                                className="tw-rounded-full tw-w-12 tw-h-12"
                                            />
                                        )
                                    }
                                    {
                                        !isOuterImage && !isInnerImage && (
                                            <FaUser className="tw-text-5xl tw-bg-gray-500 tw-p-3 tw-text-white tw-rounded-full" />
                                        )
                                    }
                                </div>
                                <div className="tw-flex-grow">
                                    <div className="tw-font-bold tw-overflow-hidden">{languageVersions?.name}</div>
                                    <div className="tw-text-xs tw-overflow-hidden">{userTitle?.value}</div>
                                </div>
                                <div className="tw-pl-4 tw-m-auto">
                                    { props.isWhatsApp && <FaWhatsapp className={clsx("tw-text-3xl tw-text-gray-400", {"!tw-text-whatsapp":userStatus})} /> }
                                    { props.isMessenger && <FaFacebookMessenger className={clsx("tw-text-3xl tw-text-gray-400", {"!tw-text-messenger":userStatus})} /> }
                                </div>
                            </div>
                        </a>
                    )
                })
            }
        </>
    )

}

function MNPG013 ( props: TMainPageLayout<PropsFromRedux> ) {
    console.log("MNPG013.rendered", props)

    const [ popover, setPopover ] = useState<string|null>(null);

    const chatWithUsString = LanguageVersionSelector<ILanguageVersionList|null>({
        languageVersions: props.parentPayload.languageVersions,
        filters:[{ view: EView.META_DATA, name: "chatWithUsString" }],
        type: "find"
    });

    const startConversation = LanguageVersionSelector<ILanguageVersionList|null>({
        languageVersions: props.parentPayload.languageVersions,
        filters:[{ view: EView.META_DATA, name: "startConversation" }],
        type: "find"
    });

    const workingHoursString = LanguageVersionSelector<ILanguageVersionList|null>({
        languageVersions: props.parentPayload.languageVersions,
        filters:[{ view: EView.META_DATA, name: "workingHoursString" }],
        type: "find"
    });

    const workingHoursEnd = LanguageVersionSelector<ILanguageVersionList|null>({
        languageVersions: props.parentPayload.languageVersions,
        filters:[{ view: EView.META_DATA, name: "workingHoursEnd", isActive: true}],
        type: "find"
    });

    const workingHoursStart = LanguageVersionSelector<ILanguageVersionList|null>({
        languageVersions: props.parentPayload.languageVersions,
        filters:[{ view: EView.META_DATA, name: "workingHoursStart", isActive: true }],
        type: "find"
    });

    const workingHoursEndString = LanguageVersionSelector<ILanguageVersionList|null>({
        languageVersions: props.parentPayload.languageVersions,
        filters:[{ view: EView.META_DATA, name: "workingHoursEndString" }],
        type: "find"
    });

    const workingHoursStartString = LanguageVersionSelector<ILanguageVersionList|null>({
        languageVersions: props.parentPayload.languageVersions,
        filters:[{ view: EView.META_DATA, name: "workingHoursStartString" }],
        type: "find"
    });

    return (
        <>
            {
                props.childsPayload.map((item, index) => {
                    
                    const languageVersions = LanguageVersionSelector<ILanguageVersionList|null>({
                        languageVersions: item.languageVersions,
                        filters:[{ view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }],
                        type: "find"
                    });

                    const isMessenger = LanguageVersionSelector<ILanguageVersionList|null>({
                        languageVersions: item.languageVersions,
                        filters:[{ view: EView.META_DATA, name: "socialMediaChatboxMessenger" }],
                        type: "find"
                    }) !== null;

                    const isWhatsApp = LanguageVersionSelector<ILanguageVersionList|null>({
                        languageVersions: item.languageVersions,
                        filters:[{ view: EView.META_DATA, name: "socialMediaChatboxWhatsapp" }],
                        type: "find"
                    }) !== null;

                    const _style = { bottom: `${96+(index*(56))}px`, zIndex: 15-index }

                    return (
                        <div className="tw-fixed tw-right-4 md:tw-right-5 tw-flex" style={_style}>
                            <span className={clsx("tw-bg-gray-100 tw-px-4 tw-py-2 tw-border tw-m-auto tw-text-sm tw-mr-2 tw-z-10 tw-transition-opacity tw-ease-in-out tw-duration-500 tw-rounded-lg ck-content", { "tw-opacity-0 tw-invisible":popover === item.id }, { "tw-opacity-100 tw-visible":popover!==item.id })} dangerouslySetInnerHTML={{ __html: SanitizeHTMLString(chatWithUsString?.value) }} />
                            <button
                                className="tw-border-none tw-bg-transparent"
                                onClick={(event) => { if (item.id === popover) setPopover(null); else setPopover(item.id); }}
                            >
                                { isWhatsApp && <FaWhatsapp className="tw-text-5xl tw-bg-whatsapp tw-p-3 tw-text-white tw-rounded-lg" /> }
                                { isMessenger && <FaFacebookMessenger className="tw-text-5xl tw-bg-messenger tw-p-3 tw-text-white tw-rounded-lg" /> }
                            </button>
                            <div className={clsx("tw-absolute tw-flex tw-flex-col tw-rounded-lg tw-right-0 tw-bottom-14 tw-w-96 tw-transition-opacity tw-ease-in-out tw-duration-500 tw-bg-white tw-shadow-xl", { "tw-opacity-0 tw-invisible":popover!==item.id }, { "tw-opacity-100 tw-visible":popover===item.id })}>
                                <div className={clsx("tw-p-4 tw-inline-flex tw-rounded-tl-lg tw-rounded-tr-lg", {"tw-bg-whatsapp":isWhatsApp},{"tw-bg-messenger":isMessenger})}>
                                    <div className="tw-pr-2">
                                        { isWhatsApp && <FaWhatsapp className="tw-text-4xl tw-text-white" /> }
                                        { isMessenger && <FaFacebookMessenger className="tw-text-4xl tw-text-white" /> }
                                    </div>
                                    <div>
                                        <div className="tw-text-lg tw-text-white tw-font-semibold tw-pb-2 tw-leading-4">{startConversation?.value}</div>
                                        <div className="tw-text-xs tw-text-white ck-content" dangerouslySetInnerHTML={{ __html: SanitizeHTMLString(languageVersions?.value) }} />
                                    </div>
                                </div>
                                {
                                    workingHoursStart && workingHoursEnd && (
                                        <p className="tw-bg-slate-200 tw-font-semibold tw-p-2 tw-text-sm tw-text-center tw-m-0">
                                            {workingHoursString?.value} {workingHoursStartString?.value} - {workingHoursEndString?.value}
                                        </p>
                                    )
                                }
                                
                                <div className="tw-px-6 tw-pt-4 tw-pb-6 tw-flex tw-flex-col tw-gap-4">
                                    <UserTemplate 
                                        parentPayload={item} 
                                        childsPayload={props.mainPageItemsListPayload?.filter((x) => x.parentId === item.id)} 
                                        siteSettingPayload={props.siteSettingDetailPayload} 
                                        isMessenger={isMessenger} 
                                        isWhatsApp={isWhatsApp} 
                                        workingHoursStart={workingHoursStart?.value}
                                        workingHoursEnd={workingHoursEnd?.value}
                                    />
                                </div>
                            </div>
                        </div>
                    );
                })
            }
        </>
    )

}

const Component = React.memo(MNPG013, ( prevProps:TMainPageLayout<PropsFromRedux>, nextProps:TMainPageLayout<PropsFromRedux> ) => {
    console.log("MNPG013.memo", { prevProps, nextProps });
    return true;
});

export default connector(Component);
