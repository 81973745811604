import React, { useRef, useState, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { isEmpty } from "lodash";
import { Dispatch } from "@reduxjs/toolkit";
// actions
import MessagePublisherAction from "core/actions/common/MessagePublisherAction";
import ContactUsServiceAction from "cms/actions/components/ContactUs/ContactUsServiceAction";
// components
import RMDRTextBox from "components/RMDRTextBox";
import RMDRTextArea from "components/RMDRTextArea";
import RMDRButton from "components/RMDRButton";
// interfaces
import IClientLanguage from "core/interfaces/IClientLanguage";
import IClientSiteSettingDetail from "cms/interfaces/IClientSiteSettingDetail";
import IBase from "cms/interfaces/IBase";
// enums
import { EView } from "cms/enums/EView";
import { EViewType } from "cms/enums/EViewType";
// type
import TValidations from "cms/types/TValidations";
// utilities
import LanguageVersionSelector from "cms/utilities/LanguageVersionSelector";
import ILanguageVersionDetail from "cms/interfaces/ILanguageVersionDetail";
import { EContactUsStatus } from "cms/enums/EContactUsStatus";


function mapStateToProps ( state: any ) {
    console.log("ContactUs.mapStateToProps: ", state);

    const webApplicationLanguage:IClientLanguage    = state.CoreStore.language?.payload;
    const webApplicationLanguageCode:string         = webApplicationLanguage?.code;
    const webApplicationLanguageISOCode:string      = state.CoreStore.languageCode?.payload;

    const contactUsPostPayload:IBase                = state.ContactUsStore.ContactUsStore.post?.payload;
    const contactUsPostError:TValidations[]|null    = state.ContactUsStore.ContactUsStore.post?.error?.value;
    const contactUsPostIsLoading:boolean            = state.ContactUsStore.ContactUsStore.post?.isLoading;

    const siteSettingDetailPayload:IClientSiteSettingDetail    = state.SiteSettingStore.details?.payload;

    return {

        webApplicationLanguage,
        webApplicationLanguageCode,
        webApplicationLanguageISOCode,

        contactUsPostPayload,
        contactUsPostError,
        contactUsPostIsLoading,
        
        siteSettingDetailPayload,

    }
}

function mapDispatchToProps ( dispatch: Dispatch ) {
    console.log("ContactUs.mapDispatchToProps");

    const contactUsServiceAction = new ContactUsServiceAction(dispatch);
    const messagePublisherAction = new MessagePublisherAction(dispatch);

    return {
        _post: ( payload:any, webApplicationLanguageCode:string ) => {
            contactUsServiceAction.post({ servicePayload: { body: { ...payload, status: EContactUsStatus.NEW }, language: webApplicationLanguageCode } })
        },
        _showMessage: ( message:string ) => { 
            messagePublisherAction.showSuccessMessage(message);
        },
        _clean: () => {
            contactUsServiceAction.postClean();
        }
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>
type TContactUs<T={}> = {

} & T;

function ContactUs ( props:TContactUs<PropsFromRedux> ) {
    console.log("ContactUs.rendered: ", props);
    
    const {
        webApplicationLanguageCode, contactUsPostPayload, contactUsPostError,
        _clean,_showMessage,_post
    } = props;

    const [ verboseResources, setVerboseResources ] = useState<any>({});
    const [ disabled, setDisabled ] = useState(false);

    const contactUsNameSurname = useRef<any>(null);
    const contactUsEmail = useRef<any>(null);
    const contactUsMessage = useRef<any>(null);
    const contactUsTitle = useRef<any>(null);

    useEffect(
        () => {
            const contactNameSurnameString = LanguageVersionSelector<ILanguageVersionDetail|undefined>({ languageVersions: props.siteSettingDetailPayload.languageVersions, filters: [{ view: EView.META_DATA, viewType: EViewType.TEXT, name: "contactNameSurnameString" }], type: 'find' });
            const contactNameSurnamePlaceholder = LanguageVersionSelector<ILanguageVersionDetail|undefined>({ languageVersions: props.siteSettingDetailPayload.languageVersions, filters: [{ view: EView.META_DATA, viewType: EViewType.TEXT, name: "contactNameSurnamePlaceholder" }], type: 'find' });
            const contactEmailString = LanguageVersionSelector<ILanguageVersionDetail|undefined>({ languageVersions: props.siteSettingDetailPayload.languageVersions, filters: [{ view: EView.META_DATA, viewType: EViewType.TEXT, name: "contactEmailString" }], type: 'find' });
            const contactEmailPlaceholder = LanguageVersionSelector<ILanguageVersionDetail|undefined>({ languageVersions: props.siteSettingDetailPayload.languageVersions, filters: [{ view: EView.META_DATA, viewType: EViewType.TEXT, name: "contactEmailPlaceholder" }], type: 'find' });
            const contactSubjectString = LanguageVersionSelector<ILanguageVersionDetail|undefined>({ languageVersions: props.siteSettingDetailPayload.languageVersions, filters: [{ view: EView.META_DATA, viewType: EViewType.TEXT, name: "contactSubjectString" }], type: 'find' });
            const contactSubjectPlaceholder = LanguageVersionSelector<ILanguageVersionDetail|undefined>({ languageVersions: props.siteSettingDetailPayload.languageVersions, filters: [{ view: EView.META_DATA, viewType: EViewType.TEXT, name: "contactSubjectPlaceholder" }], type: 'find' });
            const contactMessageString = LanguageVersionSelector<ILanguageVersionDetail|undefined>({ languageVersions: props.siteSettingDetailPayload.languageVersions, filters: [{ view: EView.META_DATA, viewType: EViewType.TEXT, name: "contactMessageString" }], type: 'find' });
            const contactMessagePlaceholder = LanguageVersionSelector<ILanguageVersionDetail|undefined>({ languageVersions: props.siteSettingDetailPayload.languageVersions, filters: [{ view: EView.META_DATA, viewType: EViewType.TEXT, name: "contactMessagePlaceholder" }], type: 'find' });

            const contactTitleString = LanguageVersionSelector<ILanguageVersionDetail|undefined>({ languageVersions: props.siteSettingDetailPayload.languageVersions, filters: [{ view: EView.META_DATA, viewType: EViewType.TEXT, name: "contactTitleString" }], type: 'find' });
            const contactDescriptionString = LanguageVersionSelector<ILanguageVersionDetail|undefined>({ languageVersions: props.siteSettingDetailPayload.languageVersions, filters: [{ view: EView.META_DATA, viewType: EViewType.TEXT, name: "contactDescriptionString" }], type: 'find' });

            const contactSubmitButtonString = LanguageVersionSelector<ILanguageVersionDetail|undefined>({ languageVersions: props.siteSettingDetailPayload.languageVersions, filters: [{ view: EView.META_DATA, viewType: EViewType.TEXT, name: "contactSubmitButtonString" }], type: 'find' });
            const contactSubmitButtonSendingString = LanguageVersionSelector<ILanguageVersionDetail|undefined>({ languageVersions: props.siteSettingDetailPayload.languageVersions, filters: [{ view: EView.META_DATA, viewType: EViewType.TEXT, name: "contactSubmitButtonSendingString" }], type: 'find' });
            const messageHasBeenSentString = LanguageVersionSelector<ILanguageVersionDetail|undefined>({ languageVersions: props.siteSettingDetailPayload.languageVersions, filters: [{ view: EView.META_DATA, viewType: EViewType.TEXT, name: "messageHasBeenSentString" }], type: 'find' });

            setVerboseResources({
                contactNameSurnameString,contactNameSurnamePlaceholder,contactEmailString,contactEmailPlaceholder,contactSubjectString,contactSubjectPlaceholder,contactMessageString,contactMessagePlaceholder,
                contactTitleString,contactDescriptionString,
                contactSubmitButtonString,contactSubmitButtonSendingString,messageHasBeenSentString
            });

            return () => {
                _clean();
            }
        }, []
    )

    useEffect(
        () => {
            if ( contactUsPostPayload?.id && !isEmpty(contactUsPostPayload?.id) ) {
                contactUsNameSurname.current.value = "";
                contactUsEmail.current.value = "";
                contactUsMessage.current.value = "";
                contactUsTitle.current.value = "";

                _clean();
                setDisabled(false);
                _showMessage(verboseResources.messageHasBeenSentString?.value||"");
            }
        }, [ contactUsPostPayload ]
    )

    useEffect(
        () => {
            if ( !isEmpty(contactUsPostError) ) {
                setDisabled(false);
            }
        }, [ contactUsPostError ]
    )
    
    const onSubmit = ( event:React.SyntheticEvent ) => { 
        event.preventDefault();
        setDisabled(true);
        const target = event.target as typeof event.target & {
            nameSurname: { value: string },
            title: { value: string },
            email: { value: string },
            message: { value: string },
        };
        const payload:any = {
            nameSurname: target.nameSurname.value,
            email: target.email.value,
            message: target.message.value,
            title: target.title.value,
        }
        _post(payload, webApplicationLanguageCode);
    }

    return (
        <form className="spacing-10 ttm-bgcolor-grey ttm-bg ttm-col-bgcolor-yes ttm-right-span" onSubmit={onSubmit} >
            {
                (verboseResources.contactTitleString || verboseResources.contactDescriptionString) && (
                    <div className="section-title with-desc clearfix">
                        <div className="title-header">
                            {
                                verboseResources.contactDescriptionString && (<h5>{ verboseResources.contactDescriptionString?.value }</h5>)
                            }
                            {
                                verboseResources.contactTitleString && (<h2 className="title">{ verboseResources.contactTitleString?.value }</h2>)
                            }
                        </div>
                    </div>
                )
            }
            
            <div id="ttm-quote-form" className="row ttm-quote-form clearfix">
                <div className="col-sm-12 col-md-12">
                    <div className="input-group mb-3 tw-flex-col">
                        <RMDRTextBox rmdrRef={contactUsNameSurname} name="nameSurname" className="form-control bg-white !tw-w-full" placeholder={verboseResources.contactNameSurnamePlaceholder?.value} disabled={disabled} errors={contactUsPostError} />
                    </div>
                </div>

                <div className="col-sm-12 col-md-12">
                    <div className="input-group mb-3 tw-flex-col">
                        <RMDRTextBox rmdrRef={contactUsEmail} name="email" className="form-control bg-white !tw-w-full" placeholder={verboseResources.contactEmailPlaceholder?.value} disabled={disabled} errors={contactUsPostError} />
                    </div>
                </div>

                <div className="col-sm-12 col-md-12">
                    <div className="input-group mb-3 tw-flex-col">
                        <RMDRTextBox rmdrRef={contactUsTitle} name="title" className="form-control bg-white !tw-w-full" placeholder={verboseResources.contactSubjectPlaceholder?.value} disabled={disabled} errors={contactUsPostError} />
                    </div>
                </div>

                <div className="col-sm-12 col-md-12">
                    <div className="input-group mb-3 tw-flex-col">
                        <RMDRTextArea rmdrRef={contactUsMessage} name="message" className="form-control bg-white !tw-w-full" placeholder={verboseResources.contactMessagePlaceholder?.value} disabled={disabled} rows={5} errors={contactUsPostError} />
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="text-left">
                        <RMDRButton
                            type="submit"
                            value={verboseResources.contactSubmitButtonString?.value}
                            disabledValue={verboseResources.contactSubmitButtonSendingString?.value}
                            disabled={disabled}
                            className="mt-3 ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor"
                        />
                    </div>
                </div>
            </div>

        </form>
    )
}

const Component = React.memo(ContactUs, ( prevProps:TContactUs<PropsFromRedux>, nextProps:TContactUs<PropsFromRedux> ) => {
    console.log("ContactUs.memo", { prevProps, nextProps });
    return !( prevProps.contactUsPostIsLoading !== nextProps.contactUsPostIsLoading );
});

export default connector(Component);
