import { isEmpty, find, filter } from "lodash";
// enums
import { EView } from 'cms/enums/EView';
import { EViewType } from 'cms/enums/EViewType';
import { EContentType } from 'cms/enums/EContentType';
// interfaces
import ILanguageVersionList from 'cms/interfaces/ILanguageVersionList';
import ILanguageVersionDetail from 'cms/interfaces/ILanguageVersionDetail';


type TLanguageVersionSelector = {

    languageVersions?: ILanguageVersionList[]|ILanguageVersionDetail[]|null;
    
    filters: {
        view?: EView;
        viewType?: EViewType;
        contentType?: EContentType;
        name?: string;
        isActive?: boolean;
        customFilter?: { key:string, value:any }[];
    }[];

    type?: 'filter'|'find';

}

function LanguageVersionSelector<T>( props:TLanguageVersionSelector ): T {

    if ( props.languageVersions && !isEmpty(props.languageVersions) ) {

        for ( let filterItem of props.filters ) {

            let predicate:any  = { isActive: true },
                data:any    = null;
            
            if ( filterItem.view ) predicate["view"] = filterItem.view;
            if ( filterItem.viewType ) predicate["viewType"] = filterItem.viewType;
            if ( filterItem.contentType ) predicate["contentType"] = filterItem.contentType;
            if ( filterItem.name ) predicate["name"] = filterItem.name;
            if ( filterItem.isActive === true || filterItem.isActive === false ) predicate["isActive"] = filterItem.isActive;
            if ( filterItem.customFilter ) {
                for ( let item of filterItem.customFilter ) {
                    predicate[item.key] = item.value;
                }
            }

            if ( props.type === 'find' ) data = find(props.languageVersions, predicate) as any;
            else if ( props.type === 'filter' ) data = filter(props.languageVersions, predicate) as any;
            
            if ( data && !isEmpty(data) ) return data;

        }
    }

    return null as any;

}

LanguageVersionSelector.defaultProps = {

    type: 'find'

}

export default LanguageVersionSelector;
export type {
    TLanguageVersionSelector
};
