import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
// components
import ContentRenderer from "components/ContentRenderer";
import ContentInnerHeader from "components/ContentInnerHeader";
import { TINNRLayout } from "components/InnerLayouts/type";
// actions
import CoreAction from "core/actions/common/CoreAction";
import ContactUsV2 from "components/ContactUsV2";


function mapDispatchToProps ( dispatch: Dispatch ) {

    const coreAction = new CoreAction(dispatch);

    return {
        _hideLoader: () => {
            coreAction.hideLoader()
        },

        _showLoader: () => {
            coreAction.showLoader()
        }
    }

}

const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>

function INNR005 ( props: TINNRLayout<PropsFromRedux> ) {

    useEffect(
        () => {

            setTimeout(() => { props._hideLoader(); }, 100);

            return function cleanup() {
                props._showLoader();
            }

        }, [ ]
    )

    return (
        <section className="ttm-row project-single-section clearfix">
            <div className="container-xl">
                <ContentInnerHeader payload={props.contentDetailPayload} />
                <ContentRenderer languageVersions={props.contentDetailPayload?.languageVersions} />

                <div className="row">
                    <div className="col-12">
                        <div className="res-991-mt-30 mt-4">
                            <ContactUsV2 parentLanguageVersions={props.contentDetailPayload?.languageVersions} />
                        </div>
                    </div>
                </div>

            </div>
        </section>
    );

}

const Component = React.memo(INNR005, ( prevProps:TINNRLayout<PropsFromRedux>, nextProps:TINNRLayout<PropsFromRedux> ) => {
    console.log("INNR005.memo", { prevProps, nextProps });
    return true;
});

export default connector(Component);
